<template>
  <BaseLoader v-if="!order" />
  <div v-else>
    <h1 class="h3 ml-2 mb-3">
      <router-link :to="`/organizations/${order.organizationId}`">{{ order.organizationName }}</router-link>
      <small>({{ order.createdAt.toDate().toLocaleDateString('de-de') }})</small>
    </h1>
    <div v-if="order.homeOrder">
      <h4>Home Order</h4>
      <table class="table mb-5">
        <thead class="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" class="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="showCart('homeOrder')" tabindex="0">
            <td>
              <div>
                <a href="#">Hausbedarf</a>
              </div>
            </td>
            <td class="text-right">
              {{
                order.homeOrder.sum
                  ? formatCurrency(_.get(order.homeOrder, 'sum', '-/-'))
                  : _.get(order.homeOrder, 'sum', '-/-')
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th scope="col"></th>
            <th scope="col" class="text-right">
              {{ formatCurrency(order.homeOrder.sum) }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div v-if="order.kioskOrder">
      <h4>Kiosk Order</h4>
      <table class="table mb-5">
        <thead class="thead-light">
          <tr>
            <th></th>
            <th scope="col">Product</th>
            <th scope="col" class="text-right">Ordered</th>
            <th scope="col" class="text-right">Packed</th>
          </tr>
        </thead>
        <tbody v-for="(item, i) in order.kioskOrder.items" :key="i" :set="(p = product(item.productId))">
          <tr tabindex="0">
            <td class="order__product-img-cell">
              <div
                class="order__product-img"
                :style="`background-image: url(https://storage.googleapis.com/emma-static/products/${p.img}_256.jpg)`"
              />
            </td>
            <td class=" align-middle">
              {{ p.brandName }} {{ p.label }} ({{ p.id }})
              <small class="d-block">{{ p.packageSize }} {{ p.packageUnit }}</small>
            </td>
            <td class="text-right  align-middle">
              <span class="badge" style="font-size:1em;">{{ item.amount }}</span>
            </td>
            <td class="text-right  align-middle">
              <span
                class="badge"
                :class="{ 'badge-warning': item.amount !== item.packed, 'badge-danger': (item.packed || 0) === 0 }"
                style="font-size:1em;"
                >{{ item.packed || 0 }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="order.residents">
      <h4>Residents</h4>
      <table class="table mb-5">
        <thead class="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" class="text-right">Total</th>
          </tr>
        </thead>
        <tbody v-for="(resident, i) in order.residents" :key="i">
          <tr @click="showCart(resident.id)" tabindex="0">
            <td>
              <div>
                <a href="#"> {{ i + 1 }}. {{ formatResidentName({ resident }) }} </a>
              </div>
            </td>
            <td class="text-right">{{ formatCurrency(_.get(resident, 'sum', '-/-')) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th scope="col"></th>
            <th scope="col" class="text-right">
              {{ formatCurrency(order.residents.reduce((t, c) => t + c.sum, 0)) }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <Layer v-if="cart" :title="cart.title" @close="cart = null" width="45rem">
      <ItemListing :items="cart.items" readonly />
    </Layer>
  </div>
</template>

<script>
import ItemListing from '@/shared/Cart/ItemListing'
import Layer from '@/shared/Layer'
import { db } from '@/shared/firebase'

export default {
  components: { ItemListing, Layer },
  data() {
    return {
      cart: null,
      order: null,
      subscription: null
    }
  },
  created() {
    this.subscription = db.doc(`orders/${this.$route.params.orderId}`).onSnapshot(doc => {
      this.order = doc.data()
    })
  },
  beforeDestroy() {
    this.subscription()
  },
  methods: {
    showCart(residentId) {
      if (residentId !== 'homeOrder') {
        const resident = this.order.residents.find(r => r.id === residentId)
        this.cart = {
          title: this.formatResidentName({ resident }),
          type: 'resident',
          items: resident.shoppingList.items
        }
      }
      if (residentId === 'homeOrder') {
        this.cart = {
          title: 'Hausbedarf',
          type: 'homeOrder',
          items: this.order.homeOrder.shoppingList.items
        }
      }
    },
    product(productId) {
      return this.$store.state.products.find(p => p.id === productId)
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  &__product-img-cell {
    width: 80px;
  }
  &__product-img {
    width: 64px;
    height: 64px;
    background-size: contain;
  }
}
</style>

<template>
  <div class="item-listing" :class="{ ['item-listing--compact']: compact }">
    <div v-if="items.length > 0">
      <ul>
        <li v-for="(item, i) in enrichedItems" :key="i" class="item-listing__item">
          <div>
            <div class="item-listing__item__product">
              <div
                class="item-listing__item__product__image"
                :style="
                  `background-image: url(https://storage.googleapis.com/emma-static/products/${item.product.img}_256.jpg)`
                "
              />
              <div class="item-listing__item__product__meta">
                <strong>{{ item.product.brandName }} {{ item.product.label }}</strong>
                <span>{{ item.product.id }}, {{ item.product.packageSize }} {{ item.product.packageUnit }}</span>
              </div>
            </div>
          </div>
          <div class="item-listing__item__price-and-amount">
            <button
              class="item-listing__item__price-and-amount__delete-button"
              @click="$emit('delete-item', item.product.id)"
              v-if="!readonly"
            >
              Löschen
            </button>
            <input
              class="item-listing__item__price-and-amount__amount-input"
              :value="item.amount.toString()"
              @input="updateAmount(item.productId, $event.target.value)"
              @blur="checkForZeroAmount(item.productId, $event.target.value)"
              min="0"
              max="999"
              type="number"
              v-if="!readonly"
            />
            <span v-else style="white-space:pre;">{{ item.amount }}&nbsp;</span>
            <span>x</span>
            <span class="item-listing__item__price-and-amount__price">
              {{ formatCurrency(item.product.retailPrice) }}
            </span>
          </div>
        </li>
      </ul>
      <table class="item-listing__price-table">
        <tr>
          <td>Liefergebühr:</td>
          <td class="item-listing__price-table__value">{{ formatCurrency(deliveryFee) }}</td>
        </tr>
        <tr v-if="containerDeposit > 0">
          <td>Pfand:</td>
          <td class="item-listing__price-table__value">{{ formatCurrency(containerDeposit) }}</td>
        </tr>
        <tr class="item-listing__price-table__sum-row">
          <td>Summe:</td>
          <td class="item-listing__price-table__value">{{ formatCurrency(sum + containerDeposit) }}</td>
        </tr>
        <tr v-if="availableBudget">
          <td>Verbleibendes Monatsbudget:</td>
          <td class="item-listing__price-table__value">
            <span
              class="item-listing__price-table__budget"
              :class="{
                'item-listing__price-table__budget--positive': availableBudget >= 0,
                'item-listing__price-table__budget--negative': availableBudget < 0
              }"
            >
              {{ formatCurrency(availableBudget) }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="item-listing__empty" v-else>
      <h3 class="h3">Die Einkaufsliste ist noch leer</h3>
      <p v-if="emptyListingText">{{ emptyListingText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    deliveryFee: {
      type: Number,
      default: 0.85
    },

    availableBudget: {
      type: Number,
      default: null
    },
    emptyListingText: {
      type: String
    },
    compact: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    enrichedItems() {
      if (!this.items.length > 0) return null
      let items = []
      this.items.forEach(item => {
        items.push({
          ...item,
          product: this.$store.state.products.find(product => product.id === item.productId)
        })
      })
      return items
    },
    sum() {
      let sum = this.enrichedItems
        .map(item => {
          return item.product.retailPrice * item.amount
        })
        .reduce((a, b) => {
          return a + b
        })
      sum += this.deliveryFee
      return sum
    },
    containerDeposit() {
      return this.enrichedItems
        .map(item => {
          return typeof item.product.containerDeposit === 'number' ? item.product.containerDeposit * item.amount : 0
        })
        .reduce((a, b) => a + b)
    }
  },
  methods: {
    updateAmount(productId, newAmount) {
      if (!isNaN(parseFloat(newAmount)) && newAmount >= 0) {
        this.$emit('update-amount', productId, parseFloat(newAmount))
      }
    },
    checkForZeroAmount(productId, amount) {
      if (parseFloat(amount) === 0) {
        this.$emit('delete-item', productId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-listing {
  min-height: 20rem;
  padding: 0 1rem 1rem;

  ul {
    @extend %cleanlist;

    padding-top: 0.5rem;
  }

  &__empty {
    background: url(../../assets/icons/cart.svg) center 4rem no-repeat;

    padding-top: 6.5rem;

    text-align: center;

    h3 {
      margin: 0 0 0.25rem;
    }

    p {
      margin: 0 auto;
      max-width: 30rem;
      font-size: 0.9375rem;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__product {
      display: flex;
      align-items: center;

      &__image {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        margin: 0 1rem 0 0.5rem;
        flex-shrink: 0;

        background-size: 3.5rem;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px #ddd solid;
        border-radius: 5px;
      }

      &__meta {
        display: flex;
        flex-direction: column;
      }
    }

    &__price-and-amount {
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
      font-weight: $weight-bold;

      &__delete-button {
        @extend %cleanbutton;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;

        background: url(../../assets/icons/trash.svg) center center no-repeat;
        border-radius: 5px;
        opacity: 0.55;

        text-indent: -9999em;

        &:focus,
        &:hover {
          border: 1px #ccc solid;
          opacity: 1;
        }
      }

      &__amount-input {
        flex-shrink: 0;
        width: 3rem;
        height: 2rem;
        display: inline-block;
        margin: 0 0.5rem 0 0;
        text-align: center;

        -moz-appearance: textfield;
        background-color: #fafafa;
        border-radius: 1.25rem;
        border: none;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25) inset;

        font-family: inherit;
        font-size: inherit;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          outline: none;
        }
      }

      &__price {
        display: inline-block;
        min-width: 4rem;

        text-align: right;
      }
    }
  }
  &__price-table {
    margin-left: auto;

    font-size: 0.9375rem;
    text-align: right;

    td {
      padding: 0 0 0.75rem;
    }

    &__sum-row {
      font-weight: $weight-bold;
    }

    &__value {
      min-width: 4.5rem;
    }

    &__budget {
      background: #ccc;
      padding: 0 0.5rem;
      margin-right: -0.5rem;
      border-radius: 5px;

      &--positive {
        background-color: rgba($color-success-green, 0.4);
      }
      &--negative {
        background-color: rgba($color-warning-red, 0.4);
      }
    }
  }
  &--compact {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    .item-listing__item {
      flex-direction: column;
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      border-bottom: 1px $color-grey solid;

      &__price-and-amount {
        align-self: flex-end;
      }
    }
  }
}

@media (max-width: 30rem) {
  .item-listing {
    &__item {
      flex-direction: column;

      &__price-and-amount {
        align-self: flex-end;
      }
    }
  }
}
</style>

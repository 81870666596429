<template>
  <div class="layer" v-if="visible" tabindex="0" ref="layer">
    <div class="layer__inner" :style="`max-width: ${width}`">
      <div class="layer__shadow">
        <header class="layer__header" :class="{ 'with-title': title }">
          <span>{{ title }}</span>
          <button class="layer__header__close" v-if="closable" @click="closeLayer">×</button>
        </header>
        <main class="layer__main" :style="`padding: ${padding}`">
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '30rem'
    },
    padding: {
      type: String,
      default: '0'
    },
    title: {
      type: String
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyPress)
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.layer.focus()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyPress)
  },
  methods: {
    closeLayer: function() {
      this.$emit('close')
    },
    onKeyPress: function(e) {
      if (e.key === 'Escape') {
        if (this.visible) {
          this.closeLayer()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './variables';

.layer {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-y: scroll;
  align-items: flex-start;

  background: rgba(darken($color-main, 20), 0.8);

  &__inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: auto;

    padding: 2rem;
  }

  &__shadow {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 7px 7px 5px 5px;
    background: #fff;
  }

  &__header {
    display: flex;
    padding: 1rem;
    justify-content: space-between;

    background: $color-main;
    border-radius: 5px 5px 0 0;

    color: #fff;
    font-size: 1.25rem;
    font-weight: $weight-bold;

    &__close {
      @extend %cleanbutton;

      padding: 0 0.5rem;
    }
  }

  &__main {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

@media (max-width: 42rem) {
  .layer {
    &__inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
